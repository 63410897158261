@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2)
      format("woff2"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff)
      format("woff"),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf)
      format("opentype");
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select {
  font-family: "Noto Sans KR", sans-serif;
}

br {
  font-family: initial !important;
}

@font-face {
  font-family: "GmarketSansLight";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/JSDongkang-RegularA1.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

a {
  color: #000 !important;
}

.fcRed {
  color: #d50000;
  font-size: 18px;
  font-weight: 400;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.mt25 {
  margin-top: 25px !important;
}

.ml10 {
  margin-left: 10px !important;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.consultingDetailTitle {
  width: 240px !important;
  padding-left: 65px !important;
}

.card:hover {
  cursor: pointer;
}

.adminBtn {
  padding: 10px 31px;
  border: 1px solid #b72226;
  background: #b72226;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}

.surveyBtn {
  padding: 10px 31px;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}

.consultingSearchBtn {
  padding: 10px 31px;
  border: 1px solid #3f51b5;
  background: #fff;
  color: #3f51b5;
  font-size: 13px;
  border-radius: 3px;
  font-weight: bold;
  cursor: pointer;
}

.tar {
  text-align: right;
}

.naviInfoFirstChar {
  font-size: 18px;
  font-weight: bold;
}

.naviInfoIcon {
  position: relative;
  top: 5px;
}

.tac {
  text-align: center !important;
}

.boxShadowNone {
  box-shadow: none !important;
}

.qnaWraper {
  padding: 20px;
}

.fileContainer {
  background: #fff;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 10px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto !important;
  transition: all 0.3s ease-in;
}

.fileContainer .uploadPictureContainer {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
}

.fileContainer .deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  background: #000;
  border-radius: 10% !important;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400 !important;
  line-height: 32px !important;
  width: 30px !important;
  height: 30px !important;
}

.fileContainer .chooseFileButton {
  padding: 10px 23px;
  background: #3f4257;
  border-radius: 5px !important;
  color: white;
  font-weight: 400;
  font-size: 12px;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
}

.fb {
}

.fs17 {
  font-size: 17px !important;
}

.mr10 {
  margin-right: 10px;
}

.maincolor {
  color: #b72026;
}

.gray {
  color: #615f5f;
}

.grayborder {
  border: 1px solid #ccc !important;
}

.pointer {
  cursor: pointer;
}

.blinking {
  -webkit-animation: blink 0.5s ease-in-out infinite alternate;
  -moz-animation: blink 0.5s ease-in-out infinite alternate;
  animation: blink 0.5s ease-in-out infinite alternate;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.consultantCategory {
  width: 140px;
  margin-bottom: 20px !important;
}

.searchInputArea {
  /* width: 140px !important; */
  /* padding-top: 16px !important; */
}

.mb25 {
  margin-bottom: 25px;
}

.consultantActiveBtn {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  border-radius: 6px;
}

.dateCategoryItem {
  width: 170px;
  padding-top: 16px !important;
}

.consultantJoinCheckboxTitle {
  font-size: 16px;
  border: 1px solid #ccc;
  padding: 15px 13px;
  border-radius: 4px;
  color: #848484;
  margin-top: 10px;
}

.fullWidthWrap {
  width: 100%;
  background-color: #3f5058;
  height: 100%;
  z-index: 99999999998;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
}

.introMainWrap {
  width: 100%;
  max-width: 1920px;
  height: 100%;

  z-index: 99999999999;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
}

.introContentWrap {
  padding: 50px 0;
  width: 33.3333%;
  color: #fff;
  font-size: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "GmarketSansLight";
}

.introOne {
  background-color: #3f5058;
}

.introOne:hover {
  background-color: #202f35;
}

.introTwo {
  background-color: #b3d6c0;
}

.introTwo:hover {
  background-color: #90bba0;
}

.introThree {
  background-color: #f5e9d9;
}

.introThree:hover {
  background-color: #ead4b6;
}

.introBtn {
  cursor: pointer;
  margin: 100px 10px 0 10px;
  padding: 10px 45px;
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
  font-size: 18px;
  border-radius: 8px;
}

.introIcon1 {
  font-size: 60px !important;
}

.introIcon2 {
  font-size: 55px !important;
}

.introLogo {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 15vw;
}

.introP {
  margin-top: 40px;
  font-size: 18px;
}

.introDescription {
  font-size: 14px;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 10s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 10s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 1s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 6s;
}

@media screen and (min-width: 1921px) {
  .introMainWrap {
    width: 100%;
    max-width: 1920px;
    height: 100%;
    z-index: 99999999999;
    position: fixed;
    left: 50%;
    margin-left: -960px;
    top: 0;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1440px) {
  .introContentWrap {
    width: 33.33%;
    color: #fff;
    font-size: 3vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .introLogo {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 20vw;
  }

  .introBtn {
    cursor: pointer;
    margin: 55px 10px 0 10px;
    padding: 8px 18px;
    background-color: transparent;
    font-size: 15px;
    border-radius: 7px;
  }

  .introP {
    margin-top: 25px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .introMainWrap {
    width: 100%;
    max-width: 1920px;
    height: 100%;
    overflow-y: auto;
    z-index: 99999999999;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .introContentWrap {
    width: 100%;
    color: #fff;
    font-size: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .introLogo {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 30vw;
  }

  .introBtn {
    cursor: pointer;

    margin: 55px 10px 0 10px;
    padding: 8px 18px;
    background-color: transparent;

    font-size: 15px;
    border-radius: 7px;
  }
}

@media screen and (max-width: 768px) {
  .introContentWrap {
    width: 100%;
    color: #fff;
    font-size: 7vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .introLogo {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 35vw;
  }

  .introBtn {
    cursor: pointer;

    margin: 55px 10px 0 10px;
    padding: 8px 18px;
    background-color: transparent;

    font-size: 15px;
    border-radius: 7px;
  }
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
